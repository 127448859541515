<template>
  <footer
    class="footer "
  >
    <div>
      <div>
        <p
          class=" mb-2 text-center"
          style="font-size:14px;color:#9c9c9c"
        >
          Copyright © 2019 - {{ year }}
          <span style="color:orange">
            Tekifun Co., Ltd.
          </span> 
          . All rights reserved.
        </p>
        <!-- <div class="text-center btn-wrapper mt-4">
          <a
            target="_blank"
            rel="noopener"
            class="btn btn-neutral btn-icon-only btn-twitter btn-round btn-lg"
            data-toggle="tooltip"
            data-original-title="Follow us"
          >
            <i class="fa fa-twitter" />
          </a>
          <a
            target="_blank"
            rel="noopener"
            class="btn btn-neutral btn-icon-only btn-facebook btn-round btn-lg"
            data-toggle="tooltip"
            data-original-title="Like us"
            href="https://www.facebook.com/Merge-Dice-Puzzle-Game-5x5-107295954346186"
          >
            <i class="fa fa-facebook-square" />
          </a>
          <a
            target="_blank"
            rel="noopener"
            class="btn btn-neutral btn-icon-only btn-dribbble btn-lg btn-round"
            data-toggle="tooltip"
            data-original-title="Follow us"
          >
            <i class="fa fa-dribbble" />
          </a>
        </div> -->
        <!-- <hr> -->
        <p
          class="text-center"
          style="font-size:16px;color:#9c9c9c"
        >
          Apple, the Apple logo, and iPad are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc.
        </p>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>
<style>
</style>
