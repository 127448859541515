<template>
  <header
    class="header-global fixed-top"
  >
    <BaseNav
      class="navbar-main stroke"
      :class="[
        {'bg-orange-custom': isShowColorHeader},
        {'shadow': isShowColorHeader}
      ]"
      :transparent="false"
      round
      :effect="effectHeader"
      type=""
      expand
      style="height:70px"
    >
      <router-link
        slot="brand"
        class="navbar-brand mr-lg-5"
        to="/"
      >
        <img
          src="img/logo.png"
          style="width:60px;height:60px"
          alt="logo"
        >
      </router-link>

      <!-- <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item mr-5">
          <a
            class="nav-txt nav-link font-weight-bold"
            style="cursor: pointer"
            href="/careers"
          >
            CAREERS
          </a>
        </li>
        <li class="nav-item mr-5">
          <a
            class="nav-txt nav-link font-weight-bold"
            style="cursor: pointer"
            href="/currentJobs"
          >
            CURRENT JOBS
          </a>
        </li>
        <li class="nav-list-item mr-5">
          <a
            class="nav-txt nav-link font-weight-bold"
            style="cursor: pointer"
          >
            MEMORIES
          </a>
        </li>
      </ul> -->
    </BaseNav>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";

export default {
  components: {
    BaseNav
  },
  data() {
    return {
      isShowColorHeader: false,
      effectHeader: 'dark'
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      this.isShowColorHeader = window.scrollY > 100
      this.effectHeader = this.isShowColorHeader ? 'light': 'dark'
    },
    onPageClicked(pageId) {
      const element = document.getElementById(pageId);
      element.scrollIntoView({ block: 'start',  behavior: 'smooth' });
      // element.scrollBy(0, 100)
    }
  }
};
</script>
<style lang="scss" scoped>
  
  .nav-txt {
    font-size: 18px;
  }
  body {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
}

small {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}

h1 {
  text-align: center;
  padding: 50px 0;
  font-weight: 800;
  margin: 0;
  letter-spacing: -1px;
  color: inherit;
  font-size: 40px;
}

h2 {
  text-align: center;
  font-size: 30px;
  margin: 0;
  font-weight: 300;
  color: inherit;
  padding: 50px;
}

.center {
  text-align: center;
}

section {
  height: 100vh;
}

// /* NAVIGATION */
// nav {
//   width: 80%;
//   margin: 0 auto;
//   background: #fff;
//   padding: 50px 0;
//   box-shadow: 0px 5px 0px #dedede;
// }
// nav ul {
//   list-style: none;
//   text-align: center;
// }
// nav ul li {
//   display: inline-block;
// }
// nav ul li a {
//   display: block;
//   padding: 15px;
//   text-decoration: none;
//   color: #aaa;
//   font-weight: 800;
//   text-transform: uppercase;
//   margin: 0 10px;
// }
nav ul li a,
nav ul li a:after,
nav ul li a:before {
  transition: all .5s;
}
// nav ul li a:hover {
//   color: #555;
// }


/* stroke */
nav.stroke ul li a,
nav.fill ul li a {
  position: relative;
}
nav.stroke ul li a:after,
nav.fill ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #aaa;
  height: 1px;
}
nav.stroke ul li a:hover:after {
  width: 100%;
}

nav.fill ul li a {
  transition: all 2s;
}

nav.fill ul li a:after {
  text-align: left;
  content: '.';
  margin: 0;
  opacity: 0;
}
nav.fill ul li a:hover {
  color: #fff;
  z-index: 1;
}
nav.fill ul li a:hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}

/* Circle */
nav.circle ul li a {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
nav.circle ul li a:after {
  display: block;
  position: absolute;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '.';
  color: transparent;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background: transparent;
}
nav.circle ul li a:hover:after {
  -webkit-animation: circle 1.5s ease-in forwards;
}

/* SHIFT */
nav.shift ul li a {
  position:relative;
  z-index: 1;
}
nav.shift ul li a:hover {
  color: #91640F;
}
nav.shift ul li a:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  content: '.';
  color: transparent;
  background: #F1C40F;
  visibility: none;
  opacity: 0;
  z-index: -1;
}
nav.shift ul li a:hover:after {
  opacity: 1;
  visibility: visible;
  height: 100%;
}



/* Keyframes */
@-webkit-keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: #333;
  }
}

/* Keyframes */
@-webkit-keyframes circle {
  0% {
    width: 1px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 1px;
    z-index: -1;
    background: #eee;
    border-radius: 100%;
  }
  100% {
    background: #aaa;
    height: 5000%;
    width: 5000%;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 0;
  }
}

.bg-orange-custom {
  background-color: orange
}
</style>
